import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'

export const axiosPost = async <T>(
  token: string,
  refreshToken: () => Promise<any>,
  endpoint: string,
  body: any,
  options?: AxiosRequestConfig
): Promise<T | { error: AxiosResponse | undefined } | undefined> => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/${endpoint}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        ...options
      })

    return res.data
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === 401) {
        void refreshToken()
      }
      return {
        error: err.response?.data
      }
    }
  }
}
